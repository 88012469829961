import React from 'react';
import { ReactComponent as QuoteIcon } from 'images/icons/icon-quote.svg';
import * as styles from './styles.module.scss';

const InlineQuote = ({ data }) => {
  const {
    primary: {
      quote_content: quoteContent,
      quoter,
    },
  } = data;

  const quoteContentHtml = quoteContent?.html;
  const quoterText = quoter?.text;

  return (
    <div className={styles.inlineQuote}>
      <QuoteIcon className={styles.quoteIcon} />
      <div dangerouslySetInnerHTML={{ __html: quoteContentHtml }} />
      <div>
        <p>&#8212; {quoterText}</p>
      </div>
    </div>
  )
};

export default InlineQuote;
