// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--f0f86";
export var animateScrollOut = "styles-module--animateScrollOut--e8850";
export var colorSequence = "styles-module--colorSequence--0fd3c";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--7dbdd";
export var grow = "styles-module--grow--11f54";
export var growAndShrink = "styles-module--growAndShrink--3d666";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--88d0e";
export var inlineQuote = "styles-module--inlineQuote--5f8d2";
export var moveBg = "styles-module--move-bg--5aca3";
export var quoteIcon = "styles-module--quoteIcon--2077f";
export var rotateLoop = "styles-module--rotateLoop--9fe93";
export var spin = "styles-module--spin--50754";
export var spinCounter = "styles-module--spinCounter--7ac27";