// extracted by mini-css-extract-plugin
export var altRow = "styles-module--altRow--2284b";
export var animateScrollIn = "styles-module--animateScrollIn--561d8";
export var animateScrollOut = "styles-module--animateScrollOut--dda08";
export var colorSequence = "styles-module--colorSequence--afccf";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--629fa";
export var grow = "styles-module--grow--5fa74";
export var growAndShrink = "styles-module--growAndShrink--e7569";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--c3350";
export var inlineTable = "styles-module--inlineTable--43883";
export var leftCol = "styles-module--leftCol--24543";
export var moveBg = "styles-module--move-bg--263ef";
export var rightCol = "styles-module--rightCol--00d66";
export var rotateLoop = "styles-module--rotateLoop--b7f3e";
export var spin = "styles-module--spin--a6031";
export var spinCounter = "styles-module--spinCounter--6bb6b";
export var tableRow = "styles-module--tableRow--17bce";