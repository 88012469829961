import React from 'react';

import * as styles from './styles.module.scss';

const InlineTable = ({ data }) => {
  const { items } = data;

  return (
    <div className={styles.inlineTable}>
      <div>
        {items.map((row, i) => {
          const { table_left_column: leftCol, table_right_column: rightCol } = row;
          const leftColHtml = leftCol?.html;
          const rightColHtml = rightCol?.html;
          const altRowClass = i % 2 === 1 ? styles.altRow : '';

          return (
            <div className={`${styles.tableRow} ${altRowClass}`} key={leftColHtml}>
              <div className={styles.leftCol} dangerouslySetInnerHTML={{ __html: leftColHtml }} />
              <div className={styles.rightCol} dangerouslySetInnerHTML={{ __html: rightColHtml }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InlineTable;
