import React from 'react';
import { BreakoutQuote as BreakoutComp } from 'components';

const BreakoutQuote = (props) => {
  const { data } = props;
  const { primary } = data;

  return <BreakoutComp {...primary} />;
};

export default BreakoutQuote;
