import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const TwoColWithMedia = ({ data }) => {
  const {
    primary: {
      gradient_headline: gradientHeadline,
      image_on_right: imgOnRight,
      media_image: image,
      two_col_media_content: content,
      two_col_media_title: title,
    },
  } = data;

  const titleText = title?.text;
  const contentHtml = content?.html;
  const gradientText = gradientHeadline?.text;
  const imageOnRight = imgOnRight ? styles.imgOnRight : '';

  return (
    <Section sliceName="TwoColWithMedia">
      <div className={`${styles.twoColMediaContainer} ${imageOnRight}`}>
        <Image className={styles.imageContainer} image={image} />
        <div className={styles.contentContainer}>
          {gradientText && (
            <h2 className={styles.gradientText}>{gradientText}</h2>
          )}
          <h3>{titleText}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
        </div>
      </div>
    </Section>
  );
};

export default TwoColWithMedia;
