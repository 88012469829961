import React from 'react';
import './styles.scss';

const Wysiwyg = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, title } = primary;

  // content check
  const wysiwygHtml = content?.html;

  // removing \t and replacing with space characters for code
  const wysiwygCleaned = wysiwygHtml
    .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
    .replace(/\[(\S+)[^\]]*][^\[]*\[\/\1\]/g, '')
    .replace(/{code}/g, '<code>')
    .replace(/{[/]code}/g, '</code>');

  const titleText = title?.text;

  return (
    <div key={wysiwygHtml} className={`wysiwyg`}>
      {titleText && (
        <h5>{titleText}</h5>
      )}
      {wysiwygHtml && (
        <div
          className="wysiwyg-content"
          dangerouslySetInnerHTML={{
            __html: wysiwygCleaned,
          }}
        />
      )}
    </div>
  );
};

export default Wysiwyg;
